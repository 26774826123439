@import "../css/variables.module";

$verticalBreakpoint: 860px;

.excalidraw {
  .dialog-mermaid {
    &-title {
      margin-bottom: 5px;
      margin-top: 2px;
    }
    &-desc {
      font-size: 15px;
      font-style: italic;
      font-weight: 500;
    }

    .Modal__content .Island {
      box-shadow: none;
    }

    @at-root .excalidraw:not(.excalidraw--mobile)#{&} {
      padding: 1.25rem;

      .Modal__content {
        height: 100%;
        max-height: 750px;

        @media screen and (max-width: $verticalBreakpoint) {
          height: auto;
          // When vertical, we want the height to span whole viewport.
          // This is also important for the children not to overflow the
          // modal/viewport (for some reason).
          max-height: 100%;
        }

        .Island {
          height: 100%;
          display: flex;
          flex-direction: column;
          flex: 1 1 auto;

          .Dialog__content {
            display: flex;
            flex: 1 1 auto;
          }
        }
      }
    }
  }

  .dialog-mermaid-body {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr auto;
    height: 100%;
    column-gap: 4rem;

    @media screen and (max-width: $verticalBreakpoint) {
      flex-direction: column;
      display: flex;
      gap: 1rem;
    }
  }

  .dialog-mermaid-panels {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    gap: 4rem;

    grid-row: 1;
    grid-column: 1 / 3;

    @media screen and (max-width: $verticalBreakpoint) {
      flex-direction: column;
      display: flex;
      gap: 1rem;
    }

    label {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      margin-bottom: 4px;
      margin-left: 4px;

      @media screen and (max-width: $verticalBreakpoint) {
        margin-top: 4px;
      }
    }

    &-text {
      display: flex;
      flex-direction: column;

      textarea {
        width: 20rem;
        height: 100%;
        resize: none;
        border-radius: var(--border-radius-lg);
        border: 1px solid var(--dialog-border-color);
        white-space: pre-wrap;
        padding: 0.85rem;
        box-sizing: border-box;
        width: 100%;
        font-family: monospace;

        @media screen and (max-width: $verticalBreakpoint) {
          width: auto;
          height: 10rem;
        }
      }
    }

    &-preview-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.85rem;
      box-sizing: border-box;
      width: 100%;
      // acts as min-height
      height: 200px;
      flex-grow: 1;
      position: relative;

      background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMUlEQVQ4T2NkYGAQYcAP3uCTZhw1gGGYhAGBZIA/nYDCgBDAm9BGDWAAJyRCgLaBCAAgXwixzAS0pgAAAABJRU5ErkJggg==")
        left center;
      border-radius: var(--border-radius-lg);
      border: 1px solid var(--dialog-border-color);

      @media screen and (max-width: $verticalBreakpoint) {
        // acts as min-height
        height: 400px;
        width: auto;
      }

      canvas {
        max-width: 100%;
        max-height: 100%;
      }
    }

    &-preview-canvas-container {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
    }

    &-preview {
      display: flex;
      flex-direction: column;
    }

    .mermaid-error {
      color: red;
      font-weight: 800;
      font-size: 30px;
      word-break: break-word;
      overflow: auto;
      max-height: 100%;
      height: 100%;
      width: 100%;
      text-align: center;
      position: absolute;
      z-index: 10;

      p {
        font-weight: 500;
        font-family: Cascadia;
        text-align: left;
        white-space: pre-wrap;
        font-size: 0.875rem;
        padding: 0 10px;
      }
    }
  }

  .dialog-mermaid-buttons {
    grid-column: 2;

    .dialog-mermaid-insert {
      &.excalidraw-button {
        font-family: "Assistant";
        font-weight: 600;
        height: 2.5rem;
        margin-top: 1em;
        margin-bottom: 0.3em;
        width: 7.5rem;
        font-size: 12px;
        color: $oc-white;
        background-color: var(--color-primary);

        &:hover {
          background-color: var(--color-primary-darker);
        }
        &:active {
          background-color: var(--color-primary-darkest);
        }

        @media screen and (max-width: $verticalBreakpoint) {
          width: 100%;
        }

        @at-root .excalidraw.theme--dark#{&} {
          color: var(--color-gray-100);
        }
      }

      span {
        padding-left: 0.5rem;
        display: flex;
      }
    }
  }
}
