@import "../css/variables.module";

.excalidraw {
  .FixedSideContainer {
    position: absolute;
    pointer-events: none;
  }

  .FixedSideContainer > * {
    pointer-events: var(--ui-pointerEvents);
  }

  .FixedSideContainer_side_top {
    left: var(--editor-container-padding);
    top: var(--editor-container-padding);
    right: var(--editor-container-padding);
    bottom: var(--editor-container-padding);
    z-index: 2;
  }

  .FixedSideContainer_side_top.zen-mode {
    right: 42px;
  }
}

/* TODO: if these are used, make sure to implement RTL support
.FixedSideContainer_side_left {
  left: var(--space-factor);
  top: var(--space-factor);
  bottom: var(--space-factor);
  z-index: 1;
}

.FixedSideContainer_side_right {
  right: var(--space-factor);
  top: var(--space-factor);
  bottom: var(--space-factor);
  z-index: 3;
}
*/
