.zoom-actions,
.undo-redo-buttons {
  background-color: var(--island-bg-color);
  border-radius: var(--border-radius-lg);
  box-shadow: 0 0 0 1px var(--color-surface-lowest);
}

.zoom-button,
.undo-redo-buttons button {
  border-radius: 0 !important;
  background-color: var(--color-surface-low) !important;
  font-size: 0.875rem !important;
  width: var(--lg-button-size);
  height: var(--lg-button-size);
  svg {
    width: var(--lg-icon-size) !important;
    height: var(--lg-icon-size) !important;
  }

  .ToolIcon__icon {
    width: 100%;
    height: 100%;
  }
}

.reset-zoom-button {
  border-left: 0 !important;
  border-right: 0 !important;
  padding: 0 0.625rem !important;
  width: 3.75rem !important;
  justify-content: center;
  color: var(--text-primary-color);
}

.zoom-out-button {
  border-top-left-radius: var(--border-radius-lg) !important;
  border-bottom-left-radius: var(--border-radius-lg) !important;

  :root[dir="rtl"] & {
    transform: scaleX(-1);
  }

  .ToolIcon__icon {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.zoom-in-button {
  border-top-right-radius: var(--border-radius-lg) !important;
  border-bottom-right-radius: var(--border-radius-lg) !important;

  :root[dir="rtl"] & {
    transform: scaleX(-1);
  }

  .ToolIcon__icon {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

.undo-redo-buttons {
  .undo-button-container button {
    border-top-left-radius: var(--border-radius-lg) !important;
    border-bottom-left-radius: var(--border-radius-lg) !important;
    border-right: 0 !important;

    :root[dir="rtl"] & {
      transform: scaleX(-1);
    }

    .ToolIcon__icon {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  .redo-button-container button {
    border-top-right-radius: var(--border-radius-lg) !important;
    border-bottom-right-radius: var(--border-radius-lg) !important;

    :root[dir="rtl"] & {
      transform: scaleX(-1);
    }

    .ToolIcon__icon {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
}
