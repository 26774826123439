@import "open-color/open-color.scss";

@mixin isMobile() {
  @at-root .excalidraw--mobile#{&} {
    @content;
  }
}

@mixin toolbarButtonColorStates {
  &.fillable {
    .ToolIcon_type_radio,
    .ToolIcon_type_checkbox {
      &:checked + .ToolIcon__icon {
        --icon-fill-color: var(--color-on-primary-container);

        svg {
          fill: var(--icon-fill-color);
        }
      }
    }
  }

  .ToolIcon_type_radio,
  .ToolIcon_type_checkbox {
    &:checked + .ToolIcon__icon {
      background: var(--color-surface-primary-container);
      --keybinding-color: var(--color-on-primary-container);

      svg {
        color: var(--color-on-primary-container);
      }
    }
  }

  .ToolIcon__keybinding {
    bottom: 4px;
    right: 4px;
  }

  .ToolIcon__icon {
    &:hover {
      background: var(--button-hover-bg);
    }

    &:active {
      background: var(--button-hover-bg);
      border: 1px solid var(--button-active-border);

      svg {
        color: var(--color-on-primary-container);
      }
    }
  }
}

@mixin outlineButtonStyles {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.625rem;
  width: var(--button-width, var(--default-button-size));
  height: var(--button-height, var(--default-button-size));
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: var(--button-border, var(--default-border-color));
  border-radius: var(--border-radius-lg);
  cursor: pointer;
  background-color: var(--button-bg, var(--island-bg-color));
  color: var(--button-color, var(--color-on-surface));

  svg {
    width: var(--button-width, var(--lg-icon-size));
    height: var(--button-height, var(--lg-icon-size));
  }

  &:hover {
    background-color: var(--button-hover-bg, var(--island-bg-color));
    border-color: var(
      --button-hover-border,
      var(--button-border, var(--default-border-color))
    );
    color: var(
      --button-hover-color,
      var(--button-color, var(--text-primary-color, inherit))
    );
  }

  &:active {
    background-color: var(--button-active-bg, var(--island-bg-color));
    border-color: var(--button-active-border, var(--color-primary-darkest));
  }

  &.active {
    background-color: var(
      --button-selected-bg,
      var(--color-surface-primary-container)
    );
    border-color: var(
      --button-selected-border,
      var(--color-surface-primary-container)
    );

    &:hover {
      background-color: var(
        --button-selected-hover-bg,
        var(--color-surface-primary-container)
      );
    }

    svg {
      color: var(--button-color, var(--color-on-primary-container));
    }
  }
}

@mixin filledButtonOnCanvas {
  border: none;
  box-shadow: 0 0 0 1px var(--color-surface-lowest);
  background-color: var(--color-surface-low);

  &:active {
    box-shadow: 0 0 0 1px var(--color-brand-active);
  }
}

$theme-filter: "invert(93%) hue-rotate(180deg)";
$right-sidebar-width: "302px";

:export {
  themeFilter: unquote($theme-filter);
  rightSidebarWidth: unquote($right-sidebar-width);
}
